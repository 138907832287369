import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthServiceProvider, AuthService} from "./auth.service";
import {catchError, shareReplay, switchMap} from "rxjs/operators";

/*
 Service responsible for attaching proper Authorization Headers to api requests
 Jwt interceptor recognises services which should be authorized by CASA token
*/
@Injectable()
export class JwtInterceptorService  implements HttpInterceptor {
  private apiEndpoint = environment.apiEndpoint;
  private authServerEndpoint = environment.authEndpoint;
  private cspaServeEndpoint = environment.casaRoot;
  private bookingEndpoint = environment.bookingEndpoint;
  constructor(private authService: AuthServiceProvider) { }

  filter(req: HttpRequest<any>): boolean {
    if (req.url.startsWith(this.apiEndpoint)) { return true; }
    if (req.url.startsWith(this.authServerEndpoint)) { return true; }
    if (req.url.startsWith(this.cspaServeEndpoint)) { return true; }
    if (req.url.startsWith(this.bookingEndpoint)) { return true; }
    if (req.url.startsWith(environment.cspaApiEndpoint)) {return true;}
    return false;
  }

  applyHeaders(auth: AuthService, next: HttpHandler, req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (!auth.isTokenValid() || !auth.getAccessToken()) {
      auth.clear();
      return next.handle(req);
    }
    const headers = { Authorization: `Bearer ${auth.getAccessToken()}`};
    return next.handle(req.clone({setHeaders: headers}))
  }

  lastRefreshed = 0;
  lastRefreshShare: Observable<any>;
  tryRefreshToken(auth: AuthService): Observable<any> {
    if (Date.now() - this.lastRefreshed < 2000) return this.lastRefreshShare;
    this.lastRefreshed = Date.now();
    this.lastRefreshShare = auth.refreshToken().pipe(shareReplay(1));
    return this.lastRefreshShare;
  }

  isUnprotectedRequest(req: HttpRequest<any>): boolean {
    return (req.url.startsWith(this.authServerEndpoint) &&
        (req.url.endsWith('/authorize') || req.url.endsWith("/token"))
      );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (!this.filter(req) || this.isUnprotectedRequest(req)) {
      return next.handle(req);
    }

    return this.authService.get().pipe(
      switchMap( api =>
        this.applyHeaders(api, next, req).pipe(
          catchError(error => {
            // handle 401 responses only  - requires a refresh token
            if (!(error instanceof HttpErrorResponse) || error.status != 401) return throwError(() => error);
            // if (!(error instanceof HttpErrorResponse) || (error.status != 401 && error.status != 403)) return throwError(() => error);
            return this.tryRefreshToken(api).pipe(
              switchMap( _ => this.applyHeaders(api, next, req))
            )
          }),
          catchError( error => {
            if (!(error instanceof HttpErrorResponse) || error.status != 401) return throwError(() => error);
            api.logout();
            return throwError(() => error);
          })
        )
      )
    )
  }
}
