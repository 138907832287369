import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { StudentRestService } from 'src/app/services/rest/student-rest.service';
import { Subscription } from 'rxjs';
import { Pageable, Page, ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { LessonBundeFilter, ApiLessonBundle } from 'src/app/model/rest/rest-model';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
// import { PusherService } from 'src/app/services/shared/pusher.service';
import { Router } from '@angular/router';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

export class StudentCreditsComponentEvent extends ComponentEvent {
}

@Component({
  selector: 'app-student-credits',
  templateUrl: './student-credits.component.html',
  styleUrls: ['./student-credits.component.css']
})
export class StudentCreditsComponent extends StateAwareComponent<StudentCreditsComponentEvent> implements OnInit, OnDestroy {

  bundles: ApiLessonBundle[];
  bundleFilter: LessonBundeFilter;
  pageQuery: Pageable;
  lastPageResult: Page<ApiLessonBundle>;
  _lang: string;
  hasNext = false;
  _studentId: number;
  scheduleSubscription: Subscription;
  creditsTotal: number;
  // pusherSubscription: Subscription;
  wsSubscription: Subscription
  @Output()
    freeCreditEvent = new EventEmitter()
  @Input() _introductionState: string

  ngOnDestroy(): void {
    if (this.scheduleSubscription) {
      this.scheduleSubscription.unsubscribe();
    }
    // if (this.pusherSubscription) { this.pusherSubscription.unsubscribe(); }

    if(this.wsSubscription) this.wsSubscription.unsubscribe()
  }

  constructor(
    private studentRest: StudentRestService,
    private appEvents: AppEventsService,
    // private pusherService: PusherService,
    private router: Router,
    private webSocket: WebSocketService
  ) {
    super();
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.listenToWebSocketCreditsUpdate();
    this.initQuery();
  }

  get studentId() {
    return this._studentId;
  }

  listenToWebSocketCreditsUpdate(): any {
    if (!this._studentId) {return; }
    // this.pusherSubscription = this.pusherService.join('student.' + this._studentId, 'creditsUpdate').subscribe( msg => {
    //   this.initQuery();
    // });
    this.wsSubscription = this.webSocket.establish(environment.lessonWsEndpoint)
      .pipe(
        switchMap(connection => connection.subscribe(
            `/col/student/${this.studentId}/creditsUpdate`
          )
        )
      ).subscribe(() =>
        this.initQuery()
      )

  }

  ngOnInit() {
    if (this.appEvents) {
      this.scheduleSubscription = this.appEvents.creditsUpdate.subscribe(
        newSchedule => {
          this.initQuery();
        }
      );
    }
  }

  private loadAvailableBundles( filter: LessonBundeFilter, pageable: Pageable) {
    return this.studentRest.findStudentLessonBundles(
      this._studentId,
      filter,
      pageable
    );
  }

  private initQuery() {
    if (!this._studentId || !this._lang) return;
    this.eventLoading();
    this.clearQueryData();
    this.bundleFilter = new LessonBundeFilter(this._lang, true);
    this.pageQuery = Pageable.of(0, 1000, null);
    this.callAppendQuery();
  }

  callAppendQuery() {
    this.eventLoading();
    this.loadAvailableBundles(this.bundleFilter, this.pageQuery)
    .subscribe( result => {
      this.lastPageResult = result;
      this.bundles = this.bundles.concat(result.content);
      this.hasNext = ( !result.last );
      this.creditsTotal = 0;
      this.bundles.forEach( bundle => this.creditsTotal += bundle.available );
      this.eventLoaded();
      this.eventNumberOfElements(result.totalElements);
      this.appEvents.credits(this._lang).next(this.creditsTotal);
    }, e => this.eventLoaded());
  }

  loadNext() {
    this.moveNext();
    this.callAppendQuery();
  }

  moveNext() {
    if (!this.hasNext) { return; }
    this.pageQuery = this.pageQuery.next();
  }

  clearQueryData() {
    this.bundles = [];
    this.bundleFilter = null;
    this.pageQuery = null;
    this.lastPageResult = null;
    this.hasNext = false;
  }

  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
    if (lang == null) {
      this.clearQueryData();
      return;
    }

    this.initQuery();
  }

  getFreeCredit() {
    this.freeCreditEvent.emit()
  }

  goToDetails() {
    this.router.navigate(
      [`/student/${this.studentId}/wallet/${this._lang}`]
    )
  }
  goToBuy() {
    this.router.navigate(
      [`/student/${this.studentId}/buy-courses/product/${this._lang}`]
    )
  }
}
