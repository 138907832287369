import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { interval, Subscription, zip } from "rxjs";
import { auditTime, switchMap, tap } from "rxjs/operators";
import { StateAwareComponent } from "src/app/model/rest/base";
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile} from "src/app/model/rest/rest-model";
import { StudentRestService } from "src/app/services/rest/student-rest.service";
import { TimeUnits } from "src/app/utils/calendar-utils";
import { StudentCommingLessonComponentEvent } from "../student-comming-lesson/student-comming-lesson.component";
import {StudentCacheProxyService} from "../../../services/student-cache-proxy.service";
import { WebSocketService } from "src/app/services/web-socket.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-student-comming-lesson-list",
  templateUrl: "./student-comming-lesson-list.component.html",
  styleUrls: ["./student-comming-lesson-list.component.css"],
})
export class StudentCommingLessonListComponent
  extends StateAwareComponent<StudentCommingLessonComponentEvent>
  implements OnInit, OnDestroy
{
  commingLessons: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[];
  private _studentId: number;
  reloadLessonIntervalSubscription: Subscription;
  wsSubscription: Subscription

  @Output()
  protected stateEvent = new EventEmitter<StudentCommingLessonComponentEvent>();
  @Input()
  _introductionState;
  @Input()
  set studentId(studentId: number) {
    if (!studentId) return;
    this._studentId = studentId;
    this.loadCommingLesson();
    this.listenToWebSocket();
  }
  get studentId(): number {
    return this._studentId;
  }

  constructor(
    private studentRest: StudentRestService,
    private webSocket: WebSocketService,
    private studentCache: StudentCacheProxyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reloadLessonIntervalSubscription = interval(
      TimeUnits.Minues(1).toMilis()
    ).subscribe(() => this.loadCommingLesson());
  }

  ngOnDestroy(): void {
    this.reloadLessonIntervalSubscription.unsubscribe();
    this.wsSubscription.unsubscribe();
  }

  listenToWebSocket(): any {
    if (!this._studentId) {return; }
    this.wsSubscription = this.webSocket.establish(environment.lessonWsEndpoint).pipe(
      switchMap(connection => zip(
        connection.subscribe(
          `/col/student/${this._studentId}/LessonListUpdate`
        ),
        connection.subscribe(
          `/col/student/${this._studentId}/LessonUpdate`
        )
      )),
      auditTime(1000)
    ).subscribe(() => {
      this.loadCommingLesson()
    })
  }

  loadCommingLesson() {
    this.eventLoading();
    if (!this._studentId) return;
    this.studentRest
      .findUpcomingNextLessonsMultiple(this.studentId)
      .pipe(
        tap((lessons) => (this.commingLessons = lessons)),
        tap((_) => this.eventLoaded(this.commingLessons)),
        tap((_) => this.eventNumberOfElements(this.commingLessons ? 1 : 0))
      )
      .subscribe();
  }

  onCommingLessonEvent($event: any) {
    this.stateEvent.emit($event);
  }

  closeCalendarTooltip() {
    this.studentCache.updateIntroductionState(Number(this._studentId), 'finished').subscribe();
  }
}
