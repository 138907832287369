<div *ngIf="commingLessons?.length">
  <div class="card">
    <div class="card-header d-flex align-items-center border-0">
      <span class="me-auto" i18n="@@student-comming-lesson.next-lesson">Next Lesson</span>
      <app-tooltip class="ms-3" [showContent]="_introductionState === 'futureLesson'" [cancelable]="_introductionState !== 'futureLesson'">
        <content>
          <p class="mb-0" i18n='@@induction-box.tooltip-next-lesson'>Here, you'll find the details of your next lesson. A few minutes before the lesson, log in and wait for the Classroom to open. You'll see a button that will take you to the lesson in your browser.</p>
          <button *ngIf="_introductionState === 'futureLesson'" class="btn btn-outline-accent btn-sm rounded-pill px-3 float-end" i18n="@@common.ok" (click)="closeCalendarTooltip()">Ok</button>
        </content>
      </app-tooltip>
    </div>
    <div class="card-body py-0">
      <ng-container *ngFor="let lessonDetails of commingLessons">

        <app-student-comming-lesson [commingLessonDetails]="lessonDetails" [studentId]="studentId"></app-student-comming-lesson>
      </ng-container>
    </div>
  </div>
</div>
