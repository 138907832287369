import { Component, OnInit, OnDestroy } from '@angular/core';
import { StudentContextService } from 'src/app/services/ctx/student-context.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute,  Router, RouterEvent, Event } from '@angular/router';
import { tap, filter } from 'rxjs/operators';
import { LangProductMapper } from 'src/app/utils/lang-mappers';
import { ApiPerson, ApiPersonalProfile } from 'src/app/model/rest/rest-model';
import { PersonNameExtractor, ProfilePhotoUrlExtractor } from 'src/app/utils/profile-photo-url-extractor';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { StaticPagesUrlProviderService } from 'src/app/services/shared/url-provider.service';
import { ThemeService } from 'src/app/services/theme.service';
import {AuthServiceProvider} from "../../../services/col2/auth.service";


@Component({
  selector: 'app-student-menu',
  templateUrl: './student-menu.component.html',
  styleUrls: ['./student-menu.component.css'],
})

export class StudentMenuComponent implements OnInit, OnDestroy {
  langs: string[];
  supportedLangsSubscription: Subscription;
  lang: string = 'en';
  langName: string;
  langSubscription: Subscription;
  studentId: string;
  selfPerson: ApiPerson<ApiPersonalProfile>;
  teachersUrl: string;
  activePath: string
  activeSegments: string[]
  isLoading: boolean = true
  theme: string

  constructor(private authService: AuthServiceProvider,
    private studentContext: StudentContextService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private studentCacheProxy: StudentCacheProxyService,
    private teachersUrlProviderService: StaticPagesUrlProviderService,
    public themeService: ThemeService
    ) {
      router.events.pipe(
        filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
      ).subscribe((e: RouterEvent) => {
          this.activePath = e['urlAfterRedirects']
          this.activePath? this.activeSegments = this.activePath.split('/') : ''
      });
    }

  ngOnInit() {
    this.teachersUrlProviderService.teachersBaseSubject.pipe(filter( url => url != null )).subscribe(
      e => this.teachersUrl = e.urlBase + '?productCode=' + e.productCode
    );

    this.supportedLangsSubscription = this.studentContext.supportedLangsSubject
    .pipe( tap( langs => this.langs = langs))
    .subscribe( );
    this.langSubscription = this.studentContext.langCodeSubject
    .pipe( tap( lang => this.lang = lang))
    .subscribe( lang => {
      this.findLangName();
    });

    // wait for student id
    this.activatedRoute.children[0].paramMap.subscribe( params => {
      this.studentId = params.get('studentId');
      this.loadSelf();
      this.isLoading = false
    });
    this.themeService.getTheme().subscribe(theme =>
      this.theme = theme
    )
  }

  getCurrentLang() {
    return this.langName
  }

  getLangClass() {
    if (this.lang) {
      if (this.lang === 'en.bs') {
        return 'en-bs';
      }
      return this.lang;
    } else {
      return 'def-course';
    }
  }

  loadSelf(): any {
    if (!this.studentId) { return; }
    this.studentCacheProxy.loadSelfPerson(Number(this.studentId)).pipe(
      tap( selfPerson => this.selfPerson = selfPerson)
    ).subscribe();
  }

  getName(person: ApiPerson<ApiPersonalProfile>) {
    return PersonNameExtractor.getPersonName(person);
  }

  getPhoto(person: ApiPerson<ApiPersonalProfile>) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(person);
  }

  ngOnDestroy(): void {
    if (this.supportedLangsSubscription) { this.supportedLangsSubscription.unsubscribe(); this.supportedLangsSubscription = null; }
    if (this.langSubscription) { this.langSubscription.unsubscribe(); this.langSubscription = null; }
  }
  mapToName(code: string): string {
    return LangProductMapper.mapLangCodeToLangName(code);
  }

  findLangName() {
    if (!this.lang ) { return; }
    this.langName = this.mapToName(this.lang);
  }

  switchLang(lang: string) {
    this.studentContext.switchLang(lang);
    this.router.navigate(['student', this.studentId, 'dashboard']);
  }

  public logout(): void {
    this.authService.get().subscribe(api => api.logout());
  }

  getStudentName(){
    return this.selfPerson? `${this.selfPerson?.name} ${this.selfPerson?.surname}` : null
  }
}
