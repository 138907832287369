<div #calendar class="week-calendar px-2 px-sm-3 px-md-4">
  <div class="cal-header border-bottom border-secondary">
    <div class="row align-items-center py-2">
      <div class="col" style="line-height: 1.3;">
        <span *ngIf="_currentDate" i18n="@@common.monthName">{_currentDate | date: 'M', select, 1 {January} 2 {February} 3 {March} 4 {April} 5 {May} 6 {June} 7 {July} 8 {August} 9 {September} 10 {October} 11 {November} 12 {December}}</span>&nbsp;
        <span *ngIf="_currentDate" >{{_currentDate | date: 'yyyy'}}</span>
        <small class="text-muted d-none d-sm-inline"><br><span i18n="calendar timemezone info@@week-calendar.timezone-info">Calendar shows your local time</span></small>
      </div>
      <div class="col-auto text-end">
        <div class="btn-group">
          <div class="btn btn-outline-secondary text-success rounded-start f-14" (click)="moveDays(-7)"><i class="fa fa-chevron-left"></i> </div>
          <div class="btn btn-outline-secondary text-white f-14" (click)="today()">{{getWeekStartDate() | date: 'dd'}} - {{getWeekEndDate() | date: 'dd MMM'}}</div>
          <div class="btn btn-outline-secondary text-danger rounded-end f-14" (click)="moveDays(7)"><i class="fa fa-chevron-right"></i></div>
        </div>
      </div>
      <div class="col-auto ms-auto">
        <app-tooltip id="tooltipBtn" class="d-flex rounded-3 ms-auto" [showContent]="_introductionState === 'reserveProva'" [cancelable]="_introductionState !== 'reserveProva'">
          <content>
            <p i18n='@@induction-box.tooltip-calendar'>The booking calendar shows our Teachers' availability. Select a date, time and teacher for your next lesson. You will be asked to confirm your selection to complete the booking. Once you complete a booking, one lesson credit will be taken from your account. You can cancel a booking >12 hours in advance. When you cancel a booking, the lesson credit that you used for it returns to your account and you can use it to book another lesson.</p>
              <button *ngIf="_introductionState === 'reserveProva'" class="btn btn-sm btn-outline-accent rounded-pill px-3 float-end" i18n="@@common.ok" (click)="closeCalendarTooltip()">Ok</button>
          </content>
        </app-tooltip>
      </div>
    </div>
    <div class="text-end d-block d-sm-none text-center">
      <small class="text-muted" i18n="calendar timemezone info@@week-calendar.timezone-info">Calendar shows your local time</small>
    </div>
  </div>
  <div class="wcal">
    <div class="wcal-hours-col wc-head-width">
      <div class="wcal-empty wc-head-height border-end border-bottom border-secondary">
      </div>
      <div #hoursScroll class="wcal-hours-scroll-wrapper wc-height">
        <div class="wcal-hours-panel border-end border-secondary">
            <div *ngFor="let hour of getHoursList()" class="wcal-hour wc-col-height border-bottom border-secondary">
              <span class="me-sm-2">{{ hour }}</span>
            </div>
        </div>
      </div>
    </div>
    <div class="wcal-grid-col">
      <div #daysScroll class="wcal-days-scroll-wrapper">
        <div class="wcal-days-panel wc-head-height wc-width d-flex border-secondary">
          <div class="wcal-day wc-col-width border-end border-secondary">
            <div *ngIf="_weekDates" class="text-muted">
                {{_weekDates[0] | date: 'EEE'}}<span  class="text-white">{{_weekDates[0] | date: 'dd'}}</span>
            </div>
          </div>
          <div class="wcal-day active wc-col-width border-end border-secondary">
              <div *ngIf="_weekDates" class="text-muted">
                  {{_weekDates[1] | date: 'EEE'}}<span  class="text-white">{{_weekDates[1] | date: 'dd'}}</span>
              </div>
          </div>
          <div class="wcal-day wc-col-width border-end border-secondary">
              <div *ngIf="_weekDates" class="text-muted">
                  {{_weekDates[2] | date: 'EEE'}}<span  class="text-white">{{_weekDates[2] | date: 'dd'}}</span>
              </div>
          </div>
          <div class="wcal-day wc-col-width border-end border-secondary">
              <div *ngIf="_weekDates" class="text-muted">
                  {{_weekDates[3] | date: 'EEE'}}<span  class="text-white">{{_weekDates[3] | date: 'dd'}}</span>
              </div>
          </div>
          <div class="wcal-day wc-col-width border-end border-secondary">
              <div *ngIf="_weekDates" class="text-muted">
                  {{_weekDates[4] | date: 'EEE'}}<span  class="text-white">{{_weekDates[4] | date: 'dd'}}</span>
              </div>
          </div>
          <div class="wcal-day wc-col-width">
              <div *ngIf="_weekDates" class="text-muted">
                  {{_weekDates[5] | date: 'EEE'}}<span  class="text-white">{{_weekDates[5] | date: 'dd'}}</span>
              </div>
          </div>
          <div class="wcal-day wc-col-width">
              <div *ngIf="_weekDates" class="text-muted">
                  {{_weekDates[6] | date: 'EEE'}}<span  class="text-white">{{_weekDates[6] | date: 'dd'}}</span>
              </div>
          </div>
        </div>
      </div>
      <div #gridScroll class="wcal-grid-scroll-wrapper wc-height">
        <div class="wcal-grid-pane wc-width">
            <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(0,$event)" [ngClass]="{'active': _activeDay == 0}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[0]" class="event" [ngClass]="event.classes" style="position: absolute;"
                     [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(0,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 0" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
              <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(1,$event)" [ngClass]="{'active': _activeDay == 1}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[1]" class="event" [ngClass]="event.classes" style="position: absolute;"
                    [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(1,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 1" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
              <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(2,$event)" [ngClass]="{'active': _activeDay == 2}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[2]" class="event" [ngClass]="event.classes" style="position: absolute;"
                     [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(2,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 2" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
              <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(3,$event)" [ngClass]="{'active': _activeDay == 3}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[3]" class="event" [ngClass]="event.classes" style="position: absolute;"
                     [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(3,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 3" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
              <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(4,$event)" [ngClass]="{'active': _activeDay == 4}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[4]" class="event" [ngClass]="event.classes" style="position: absolute;"
                     [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(4,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 4" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
              <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(5,$event)" [ngClass]="{'active': _activeDay == 5}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[5]" class="event" [ngClass]="event.classes" style="position: absolute;"
                     [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(5,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 5" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
              <div class="wcal-day-col wc-col-width border-end border-secondary" (click)="dayClick(6,$event)" [ngClass]="{'active': _activeDay == 6}">
                <div *ngFor="let hour of [].constructor(48)" class="wcal-day-hour wc-col-height border-bottom border-secondary"></div>
                <div *ngFor="let event of _dayEvents[6]" class="event" [ngClass]="event.classes" style="position: absolute;"
                     [ngStyle]="{
                        top: event.start + '%',
                        height: 'calc(' + event.height + '% - 2px)',
                        left: event.left + '%',
                        width: event.width + '%'
                      }"
                     (click)="eventClick(6,event,$event)">
                  <span *ngIf="event.title" class="title">{{event.title()}}</span>
                  <span *ngIf="event.subtitle" class="description">{{event.subtitle}}</span>
                </div>
                <div *ngIf="_activeDay == 6" class="currentTime" [ngStyle]="{'top': _currentTimeAsProgress + '%'}"></div>
              </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading"  class="loading-board">
        <div class="loader">
    </div>
  </div>

</div>
</div>
