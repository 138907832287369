import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LessonConfirmEvent } from 'src/app/components/student/lesson-confirmation/lesson-confirmation.component';
import { ApiPersonTechnicalProfile } from 'src/app/model/rest/rest-model';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';

@Component({
  selector: 'app-reserve-lesson-confirmation-page',
  templateUrl: './reserve-lesson-confirmation-page.component.html',
  styleUrls: ['./reserve-lesson-confirmation-page.component.css']
})
export class ReserveLessonConfirmationPageComponent implements OnInit {
  lessonId: string;
  studentId: string;
  _cancelled = false;
  _cancelling = true;
  technicalProfileUpdateSubscription: Subscription;
  _introductionState = ''

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private studentCache: StudentCacheProxyService,
    private appEvents: AppEventsService,
  ) {
    activatedRoute.params.subscribe(params => this.lessonId = params['lessonId']);
    activatedRoute.parent.params.subscribe( params => this.studentId = params['studentId']);
    this.technicalProfileUpdateSubscription = appEvents.technicalProfileUpdated.subscribe(
      newProfile => this.resolveIntroductionState(newProfile)
    );
  }

  resolveIntroductionState(studentProfile: ApiPersonTechnicalProfile): void {
    this._introductionState = studentProfile && studentProfile.introductionState ? studentProfile.introductionState : '';
  }

  onLessonConfirmationInteract(event: LessonConfirmEvent) {
    if (event === LessonConfirmEvent.CONFIRMED) {
      if(this._introductionState === 'reserveProva')
        this.studentCache.updateIntroductionState(Number(this.studentId), 'futureLesson').subscribe();
      this._cancelling = false;
      this.router.navigate(['student', this.studentId, 'dashboard']);
      return;
    } else if (event === LessonConfirmEvent.CANCELLING) {
      this._cancelling = true;
      return;
    } else if (event === LessonConfirmEvent.CANCELLED) {
      this._cancelled = true;
      this._cancelling = false;
      return;
    } else {
      this._cancelled = false;
      this._cancelling = false;
    }
  }

  isShowConfirmation() {
    return !this._cancelled;
  }

  ngOnInit() {
  }

}
