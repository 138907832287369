<app-shopping-cart></app-shopping-cart>

<div class="en">
  <nav class="navbar navbar-expand-sm p-0 m-0">
    <div class="navbar-panel d-none d-md-flex flex-column justify-content-between">
      <app-student-main-nav [activeSegments]="activeSegments" [lang]="lang"></app-student-main-nav>
    </div>
  </nav>


<div class="workspace bg-radial min-vh-100">
  <div class="top-bar">
    <div class="top-bar-content mb-md-4">
      <div class="d-flex">
        <h4 class="text-white me-3" >
          <ng-template [ngLoading]="isLoading">
            {{ getCurrentLang() }}
          </ng-template>
        </h4>
        <div class="dropdown">
          <button type="button" id="topbarDropdown"
            class="btn btn-primary rounded-circle py-1 px-2 lh-1"
            data-bs-toggle="dropdown">
            <i class="fas fa-angle-down" style="color: #000"></i>
          </button>
          <ul class="dropdown-menu dropdown-lang">
            <li *ngFor="let langAv of langs">
              <a class="dropdown-item text-muted" (click)="switchLang(langAv)" i18n="Language name@@common.langName">{mapToName(langAv), select, English {English} Spanish {Spanish} Business English {Business English} English For Children {English For Children}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="nav-container ms-auto me-md-5">
        <button type="button" class="btn btn-primary btn-bubble rounded-pill d-none d-md-block me-4 me-lg-5 py-2 px-3"
          routerLink="/student/{{studentId}}/lessons/reserve"
          i18n="@@student-menu.reserve-lesson">
          Reserve a lesson
        </button>
        <!-- face button -->
        <div class="dropdown">
            <button type="button" class="btn btn-primary btn-circle d-none d-md-block" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="far fa-smile"></i>
            </button>
            <ul class="dropdown-menu dropdown-profile mt-3" aria-labelledby="dropdownMenu2">
                <li>
                  <a tabindex="" class="dropdown-item text-muted"
                    href="#" routerLink="/student/{{studentId}}/profile"
                    i18n="Show student profile edit page@@student-menu.profile">
                    <i class="far fa-user  me-2 text-primary"></i>
                    <span class="text-muted">Edit Profile</span>
                  </a>
                </li>
                <!-- <li>
                    <a class="dropdown-item text-muted">
                        <app-icon name="sync"></app-icon>
                        Switch The Profile
                    </a>
                </li> -->
                <!-- <li>
                    <a class="dropdown-item">
                      <i class="far fa-envelope me-2 text-primary"></i>
                      <span class="text-muted">Contact</span>
                    </a>
                </li> -->
                <li>
                  <a class="dropdown-item" routerLink="./" (click)="themeService.onThemeChange(theme)">
                    <span *ngIf="theme === 'light'" class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="15" viewBox="2 0 17 20">
                        <path fill="var(--primary)" d="M10 17q-2.917 0-4.958-2.042Q3 12.917 3 10q0-2.917 2.042-4.958Q7.083 3 10 3q.271 0 .531.021.261.021.531.062-.812.605-1.291 1.5-.479.896-.479 1.917 0 1.771 1.218 2.99 1.219 1.218 2.99 1.218 1.021 0 1.917-.479.895-.479 1.5-1.291.041.27.062.531.021.26.021.531 0 2.917-2.042 4.958Q12.917 17 10 17Zm0-1.5q1.708 0 3.104-.979 1.396-.979 2.042-2.563-.417.104-.823.177-.406.073-.823.073-2.375 0-4.042-1.666Q7.792 8.875 7.792 6.5q0-.417.073-.823.073-.406.177-.823-1.584.646-2.563 2.042Q4.5 8.292 4.5 10q0 2.292 1.604 3.896T10 15.5Zm-.292-5.208Z"/>
                      </svg>
                    </span>
                    <span *ngIf="theme === 'dark'" class="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="14" viewBox="1 0 19 20">
                        <path fill="var(--primary)" d="M10 12.5q1.042 0 1.771-.729.729-.729.729-1.771 0-1.042-.729-1.771Q11.042 7.5 10 7.5q-1.042 0-1.771.729Q7.5 8.958 7.5 10q0 1.042.729 1.771.729.729 1.771.729Zm0 1.5q-1.667 0-2.833-1.167Q6 11.667 6 10q0-1.667 1.167-2.833Q8.333 6 10 6q1.667 0 2.833 1.167Q14 8.333 14 10q0 1.667-1.167 2.833Q11.667 14 10 14Zm-8.25-3.25q-.312 0-.531-.219Q1 10.312 1 10q0-.312.219-.531.219-.219.531-.219h2q.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Zm14.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219h2q.312 0 .531.219Q19 9.688 19 10q0 .312-.219.531-.219.219-.531.219ZM10 4.5q-.312 0-.531-.219-.219-.219-.219-.531v-2q0-.312.219-.531Q9.688 1 10 1q.312 0 .531.219.219.219.219.531v2q0 .312-.219.531-.219.219-.531.219ZM10 19q-.312 0-.531-.219-.219-.219-.219-.531v-2q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531v2q0 .312-.219.531Q10.312 19 10 19ZM5.042 6.104 4 5.042q-.229-.209-.229-.511 0-.302.229-.531.208-.229.521-.229.312 0 .521.229l1.062 1.042q.229.229.229.531 0 .302-.229.531-.208.229-.521.229-.312 0-.541-.229ZM14.958 16l-1.062-1.042q-.229-.229-.229-.531 0-.302.229-.531.208-.229.521-.229.312 0 .541.229L16 14.958q.229.209.229.511 0 .302-.229.531-.229.229-.521.229-.291 0-.521-.229Zm-1.062-9.896q-.229-.208-.229-.521 0-.312.229-.541L14.958 4q.23-.229.521-.219.292.011.521.219.229.229.229.521 0 .291-.229.521l-1.042 1.062q-.229.229-.531.229-.302 0-.531-.229ZM4 16q-.229-.208-.229-.521 0-.312.229-.521l1.042-1.062q.229-.208.531-.208.302 0 .531.208.229.229.219.531-.011.302-.219.531L5.042 16q-.209.229-.511.229-.302 0-.531-.229Zm6-6Z"/>
                      </svg>
                    </span>
                    <span class="text-muted">
                      {{ themeService.getThemeName(theme) }}
                    </span>
                  </a>
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li>
                    <a class="dropdown-item" routerLink="./" (click)="logout()">
                      <i class="fas fa-sign-out-alt me-2 text-primary"></i>
                      <span class="text-muted">Log Out</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="d-none d-lg-flex flex-column pe-4 border-end border-secondary">
            <span class="f-14">
              <ng-template [ngLoading]="isLoading">
                {{ getStudentName() }}
              </ng-template>
            </span>
            <!-- <span class="f-12 text-muted"> {{ getStudentRole() }}</span> -->
        </div>
        <!-- notifications button  -->
        <div class="dropdown">
            <button type="button" class="btn btn-primary btn-circle d-none d-md-block ms-2 ms-lg-3" id="notifications" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="far fa-bell"></i>
                <!-- <span class="position-absolute bottom-left rounded-circle bg-danger p-2">
                    <span class="visually-hidden">unread messages</span>
                </span> -->
            </button>
            <ul class="dropdown-menu dropdown-notifications mt-3" aria-labelledby="notifications">
                <li>
                    <p class="text-muted f-14 mb-0 text-center p-2">
                        You have no notifications yet.
                    </p>
                </li>
            </ul>
        </div>
        <button
            type="button"
            class="btn btn-primary btn-circle btn-sm d-md-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas"
            aria-controls="offcanvas">
            <i class="fas fa-bars"></i>
        </button>
        <div class="offcanvas offcanvas-end d-md-none" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasScrollingLabel">
          <div class="offcanvas-body">
            <app-student-main-nav [isOffcanvas]="true" [activeSegments]="activeSegments" [lang]="lang"></app-student-main-nav>
          </div>
        </div>
      </div>
    </div>
    <div class="top-bar-footer">
      <app-breadcrumb [studentId]="studentId"></app-breadcrumb>
    </div>
  </div>

  <router-outlet></router-outlet>
  </div>
</div>
