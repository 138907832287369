import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AppEventsService } from 'src/app/services/ctx/app-events.service';
import { StudentCacheProxyService } from 'src/app/services/student-cache-proxy.service';
import { Subscription } from 'rxjs';
import { ApiPersonalProfile, ApiPersonTechnicalProfile, ApiCountry, ApiPerson } from 'src/app/model/rest/rest-model';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/utils/utils';
import { PublicRestProxyService } from 'src/app/services/public-rest-proxy.service';
import { LocaleManagerService } from 'src/app/services/locale-manager.service';

@Component({
  selector: 'app-student-missing-data-box',
  templateUrl: './student-missing-data-box.component.html',
  styleUrls: ['./student-missing-data-box.component.css']
})
export class StudentMissingDataBoxComponent implements OnInit, OnDestroy {


  personalProfileSubscription: Subscription;
  _personalProfile: ApiPersonalProfile;
  _technicalProfile: ApiPersonTechnicalProfile;
  technicalProfileSubscription: Subscription;
  countries: ApiCountry[];
  readCountry = false;
  readNationality = false;
  readSkype = false;


  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.loadData();
  }
  _studentId: number;
  get studentId(){return this._studentId;}

  set personalProfile(profile: ApiPersonalProfile) {
    this._personalProfile = profile;
    if (!profile) {
      return;
    }
    this.readCountry = !profile.countryIso;
    this.readNationality = !profile.nationalityIso;
  }

  set technicalProfile(profile: ApiPersonTechnicalProfile) {
    this._technicalProfile = profile;
    if (!profile) {
      return;
    }
    //this.readSkype = !profile.skype;
  }

  show() {
    if (!this._technicalProfile || !this._personalProfile || this._technicalProfile.introductionState !== 'finished') {
      return false;
    }
    return this.readCountry || this.readNationality || this.readSkype;
  }

  saveData() {
    console.log(this._personalProfile);
    console.log(this._technicalProfile);
    if (this.readNationality || this.readCountry) {
      this.studentCache.savePersonalProfile(this.studentId, this._personalProfile).subscribe();
    }
    if (this.readSkype) {
      this.studentCache.saveStudentTechnicalProfile(this.studentId, this._technicalProfile).subscribe();
    }
  }


  constructor(appEvents: AppEventsService,
    private studentCache: StudentCacheProxyService,
    publicRest: PublicRestProxyService,
    localeService: LocaleManagerService
    ) {
      this.personalProfileSubscription = appEvents.personalProfileUpdated.pipe(
        map( p => Utils.jsonClone(p))
      )
      .subscribe(profile => this.personalProfile = profile);
      this.technicalProfileSubscription = appEvents.technicalProfileUpdated.pipe(
        map( p => Utils.jsonClone(p))
      ).subscribe(profile => this.technicalProfile = profile);

      publicRest.loadCountries(localeService.localeId).subscribe(countries => this.keepCountries(countries));
    }

    keepCountries(countries: ApiCountry[]): void {
      this.countries = countries.concat().sort((l, r) => l.name.localeCompare(r.name));
    }

  ngOnInit() {
  }

  private loadData() {
    if (!this.studentId) return;
    this.studentCache.loadSelfPerson(this.studentId).pipe(
      map(p => p ? p : {personalProfile: new ApiPersonalProfile()}),
      map(p => Utils.jsonClone(p))
    )
      .subscribe(self => this.personalProfile = self.personalProfile);

    this.studentCache.loadStudentTechnicalProfile(this.studentId).pipe(
      map(p => p ? p : new ApiPersonTechnicalProfile()),
      map(p => Utils.jsonClone(p))
    ).subscribe(profile => this.technicalProfile = profile);
  }

  ngOnDestroy(): void {
    this.personalProfileSubscription.unsubscribe();
    this.technicalProfileSubscription.unsubscribe();
  }

}
