<div *ngIf="_showTutorialDim" [ngClass]="{'screen-dim': isModalIntroduction()}"></div>
<video *ngIf="isModalIntroduction()" playsinline="" autoplay="" muted="" loop="" id="myVideo" class="position-fixed screen-bg w-100">
  <source src="https://assets.callan.app/callanonline/video_bg.mp4" type="video/mp4">
  Your browser does not support the video tag.
</video>
<!-- <button class="btn btn-primary" (click)="showModal()">Show modal</button>-->
<div *ngIf="!isModalIntroduction()">
    <div [hidden]="hideCommingLesson" [class]="hideCommingLesson? '' : 'mb-4 mb-md-5'" [ngClass]="{tutorial: _introductionState == 'futureLesson'}">
      <app-student-comming-lesson-list (stateEvent)="onCommingLessonEvent($event)" [studentId]="studentId" [_introductionState]="_introductionState"></app-student-comming-lesson-list>
    </div>
  <div  [hidden]="hideStarterOffers" >
    <div class="card mb-4 mb-md-5 bg-accent bg-smoke text-white bg-cover">
      <div class="card-body">
        <app-student-starters-offer (stateEvent)="onStarterOfferEvent($event)" [studentId]="studentId"></app-student-starters-offer>
      </div>
    </div>
  </div>
  <app-student-missing-data-box [studentId]="studentId"></app-student-missing-data-box>
  <div class="row">
    <div class="col-12">
      <!-- MY PROGRESS -->
      <div class="card mb-4 mb-md-5">
        <app-student-context-based-progress (reserveProvaEvent)="reserveProvaClicked()"
          [langCode]="langCode" [studentId]="studentId" [isTutorial]="_introductionState == 'reserveProva'" [isShowReserveProva]="isShowReserveProva()">
        </app-student-context-based-progress>
      </div>
      <!-- MY CREDITS  -->
      <div class="card mb-4 mb-md-5" id="credits">
          <app-student-credits (freeCreditEvent)="getFreeCredit()"
            [langCode]="langCode" [studentId]="studentId" [_introductionState]="_introductionState"></app-student-credits>
      </div>
      <!-- FUTURE LESSONS -->
      <div class="card mb-4 mb-md-5" id="futureLess">
        <div class="card-header d-flex align-items-center">
          <span i18n="@@student-dashboard.future-lessons-box.title">Future Lessons</span>
          <span *ngIf="upcomingLessons?.getTotalResults()" class="badge bg-primary ms-3">{{upcomingLessons.getTotalResults()}}</span>
          <app-tooltip class="ms-auto">
            <content>
              <p class="mb-0" i18n="@@induction-box.tooltip-future-lessons">This is a list of lessons that you have booked. You can cancel a booking and recover a credit if there's over 12 hours left until the booked lesson time.</p>
            </content>
          </app-tooltip>
        </div>
        <div class="card-body" [ngClass]="{'py-0': futureLoading}">
          <app-student-lessons
            #upcomingLessons
            (lessonDetailsEvent)="openLessonDetails($event)"
            componentRole="student"
            [langCode]='langCode'
            [lessonType]='lessonTypes.Upcomming'
            [studentId]="studentId"
          ></app-student-lessons>
        </div>
        <div class="card-footer">
          <app-pagination [pageableComponent]="upcomingLessons"></app-pagination>
        </div>
      </div>

      <!-- EXERCISES -->
        <div class="card mb-4 mb-md-5 mt-2"
          id="exercises"
          >
          <div id="tut-cspa" class="card-header d-flex justify-content-between align-items-center">
            <span i18n="@@student-dashboard.exercises-box.title" class="mb-0 me-3">Exercises</span>
            <app-tooltip class="ms-auto">
              <content>
                <p class="mb-0" i18n='@@induction-box.tooltip-exercises'>Do the exercises between lessons to practise what you've learned and consolidate your skills. Important: In browser settings, give Callan Online permission to open popups.</p>
              </content>
            </app-tooltip>
          </div>
            <app-exercise-set-host [setPath]="getCspaSet()" ></app-exercise-set-host>
        </div>

      <!-- PAST LESSONS -->
      <div class="card mb-4 mb-md-5"
        id="pastLess">
        <div class="card-header d-flex align-items-center">
          <span i18n="@@student-dashboard.past-lessons-box.title">Past lessons</span>
          <span *ngIf="pastLessons.getTotalResults()" class="badge bg-primary ms-3">{{pastLessons.getTotalResults()}}</span>
          <app-tooltip class="ms-auto">
            <content>
              <p class="mb-0" i18n='@@induction-box.tooltip-past-lessons'>All bookings you made in the past, including lessons that have taken place and cancelled bookings.</p>
            </content>
          </app-tooltip>
        </div>
        <div class="card-body pt-0">
          <app-student-lessons #pastLessons (lessonDetailsEvent)="openLessonDetails($event)" componentRole="student" [langCode]='langCode' [lessonType]='lessonTypes.Past' [studentId]="studentId"></app-student-lessons>
        </div>
        <div class="card-footer" *ngIf="!pastLoading">
          <app-pagination [pageableComponent]="pastLessons"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal for tutorial -->
<app-modal #initialModal [isFaded]="false">
  <div class="modal-content p-4">
    <div class="modal-header px-0 pt-0 justify-content-start">
      <span class="step-circle step-circle-outline-primary me-3">{{getStep()}}</span>
      <h5 *ngIf="_introductionState == 'language'" class="modal-title my-auto" i18n="Induction box - setup account title@@induction-box.title">
        Setup your account
      </h5>
      <h5 *ngIf="_introductionState == 'course'" class="modal-title my-auto" i18n="@@induction-box.select-course">Select course</h5>
      <h5 *ngIf="_introductionState == 'personalProfile'" class="modal-title my-auto" i18n="@@induction-box.about-you">About you</h5>
    </div>
    <div class="modal-body">
      <div *ngIf="_introductionState == 'language'">
        <p class="mb-5" i18n="We need to know your language@@induction-box.language-question">Before we proceed, tell us what language we should speak to you.</p>
        <form
        #langForm="ngForm" (ngSubmit)="langForm.form.valid && saveLanguage()">
          <div class="row">
            <div class="col-12 mb-5">
              <div class="form-floating">
                <select
                  class="form-select"
                  name="language"
                  id="language"
                  #languageField="ngModel"
                  [(ngModel)]="_langDialog.languageCode"
                  [ngClass]="{ 'is-invalid': langForm.submitted && languageField.invalid, 'select-not-empty' : _langDialog.languageCode}"
                  required>
                  <option *ngFor="let lang of _langDialog.languages" [value]="lang.code">{{lang.name}}</option>
                </select>
                <label for="language" i18n="@@induction-box.language-question.languages-list-title">Your preferred display language is</label>
                <div *ngIf="langForm.submitted && languageField.invalid" class="invalid-feedback">
                    <div *ngIf="languageField.errors.required" i18n="@@common.field-required">Field is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <div class="position-relative d-flex mb-3 mb-sm-0 w-100 me-sm-5">
              <span class="step-circle step-circle-outline-primary">1</span>
              <div class="horizontal-line line-half"></div>
              <span class="step-circle step-circle-outline-secondary">2</span>
              <div class="horizontal-line line-incomplete"></div>
              <span class="step-circle step-circle-outline-secondary">3</span>
            </div>
            <button class="btn btn-primary btn-bubble rounded-pill ms-auto" i18n="@@common.Next">Next</button>
          </div>
        </form>
      </div>
      <div *ngIf="_introductionState == 'course'">
        <p class="mb-3 mb-sm-5" i18n="Ask student for language to learn@@induction-box.preffered-course-question">What course do you want to take?</p>
        <div class="row text-center mb-3 mb-sm-5 g-2 row-cols-2 justify-content-center row-cols-sm-3">
          <div class="col" *ngFor="let langCode of _courseDialog.productMapper.activeLangCodes">
              <div class="d-flex flex-column justify-content-around align-items-center course-select py-2 rounded h-100"
                [ngClass]="{'bg-primary': _courseDialog.productCode == langCode}"
                (click)="_courseDialog.productCode = langCode">
                <label class="fw-bold" i18n="@@common.langName">{_courseDialog.productMapper.mapLangCodeToLangName(langCode), select, English {English} Spanish {Spanish} Business English {Business English} English For Children {English For Children}}</label>
                <input class="form-check-input" type="radio" [checked]="langCode == _courseDialog.productCode"
                >
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="position-relative d-flex mb-3 mb-sm-0 w-100 me-sm-5">
            <span class="step-circle step-circle-primary">1</span>
            <div class="horizontal-line line-complete"></div>
            <span class="step-circle step-circle-outline-primary">2</span>
            <div class="horizontal-line line-half"></div>
            <span class="step-circle step-circle-outline-secondary">3</span>
          </div>
          <div class="d-flex ms-auto">
            <button type="button" class="btn btn-secondary rounded-pill ms-auto px-3" (click)="backIntroduction()" i18n="@@common.Back">Back</button>
            <button type="button" class="btn btn-primary btn-bubble rounded-pill ms-3" (click)="_introductionState = 'personalProfile'; updateCourse()" i18n="@@common.Next" [disabled]="!_courseDialog.productCode">Next</button>
          </div>
        </div>
      </div>
      <div *ngIf="_introductionState == 'personalProfile'">
        <form #profileForm="ngForm" (ngSubmit)="profileForm.form.valid && saveProfileForm()">
          <p class="mb-5" i18n="@@induction-box.profile.form.intro">Thanks! Now let's find out a little about who you are!</p>
          <div class="row">
            <div class="col-12 mb-4">
              <div class="form-floating">
                <input
                  id="name"
                  name="name"
                  #name="ngModel"
                  placeholder="name"
                  [(ngModel)]="_profileDialog.name"
                  type="text"
                  class="form-control"
                  required
                  [ngClass]="{ 'is-invalid': profileForm.submitted && name.invalid}"
                >
                <label for="name" i18n="@@induction-box.profile.form.name.label">Name</label>
                <div *ngIf="profileForm.submitted && name.invalid" class="invalid-feedback">
                    <div *ngIf="name.errors.required" i18n="@@common.field-required">Field is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-floating">
                <input
                  id="surname"
                  name="surname"
                  #surname="ngModel"
                  placeholder="surname"
                  [(ngModel)]="_profileDialog.surname"
                  type="text"
                  class="form-control"
                >
                <label for="surname" i18n="@@induction-box.profile.form.surname.label">Surname</label>
              </div>
            </div>
            <div class="col-12 mb-5">
              <div class="form-floating">
                <select
                  id="gender"
                  name="gender"
                  #gender="ngModel"
                  [(ngModel)]="_profileDialog.gender"
                  class="form-select"
                  [ngClass]="{ 'is-invalid': profileForm.submitted && gender.invalid, 'select-not-empty' : _profileDialog.gender}"
                  required
                >
                  <option value="Male" selected i18n="@@induction-box.profile.form.gender.male.label">Male</option>
                  <option value="Female" i18n="@@induction-box.profile.form.gender.female.label">Female</option>
                  <option value="Other" i18n="@@induction-box.profile.form.gender.other.label">Other</option>
                </select>
                <label for="gender"  i18n="@@induction-box.profile.form.gender.label">Gender</label>
                <div *ngIf="profileForm.submitted && gender.invalid" class="invalid-feedback">
                    <div *ngIf="gender.errors.required" i18n="@@common.field-required">Field is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <div class="position-relative d-flex mb-3 mb-sm-0 w-100 me-sm-5">
              <span class="step-circle step-circle-primary">1</span>
              <div class="horizontal-line line-complete"></div>
              <span class="step-circle step-circle-primary">2</span>
              <div class="horizontal-line line-complete"></div>
              <span class="step-circle step-circle-outline-primary">3</span>
            </div>
            <div class="d-flex ms-auto">
              <button type="button" class="btn btn-secondary rounded-pill ms-auto px-3" (click)="backIntroduction()" i18n="@@common.Back">Back</button>
              <button class="btn btn-primary btn-bubble rounded-pill ms-3" i18n="@@common.Save">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-modal>

