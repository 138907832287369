<div id="tut-freeCredit" class="card-header border-bottom-0">
  <div class="row" [ngClass]="{tutorial: _introductionState == 'freeCredit'}">
    <div class="col-auto">
      <span i18n="@@student-dashboard.my-credits.title" class="mb-0 me-2">My credits</span>
      <span *ngIf="bundles && bundles.length>=1 && _introductionState != 'freeCredit'"
        class="badge bg-primary">
        {{creditsTotal}}
      </span>
    </div>
    <div class="col-auto ms-auto">
      <app-tooltip class="ms-3">
        <content>
          <p class="mb-0" i18n='@@induction-box.tooltip-credits'>Whenever you book a lesson, one credit is reserved from your pool. Credits come in Plans – packages with predetermined lesson frequency and duration. You can use credits to book lessons as long as they are valid, so mind the plan expiry date!</p>
        </content>
      </app-tooltip>
    </div>
  </div>
</div>
<div class="card-body">
  <div class="row">
    <ng-template [ngLoading]="!loaded" type="spinner">
      <div class="col">
        <!-- no bundle layout -->
        <div *ngIf="bundles && bundles.length==0" >
            <strong i18n="no credits@@student-credits.empty">You have 0 credits.</strong>
        </div>
        <!-- multi bundle layout -->
        <div *ngIf="bundles && bundles.length>=1" >
          <table class="table table-borderless pe-none">
            <thead>
              <tr>
                <th i18n="@@student-credits.table.plan">Plan</th>
                <th i18n="@@student-credits.table.credits-number">Credits</th>
                <th i18n="@@student-credits.table.expiry-date">Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bundle of bundles">
                <td>{{bundle.name}}</td>
                <td>{{bundle.available}}</td>
                <td>{{bundle.expiryDate | date: 'dd MMM yyyy'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<div class="card-footer text-end">
  <button *ngIf="_introductionState != 'freeCredit'"
    class="btn btn-primary me-3 rounded-pill btn-bubble"
    i18n="@@student-dashboard.buy-more-credits.btn"
    (click)="goToBuy()">
    <!-- routerLink="/student/{{studentId}}/buy-courses/product/{{langCode}}" -->
    Buy more
  </button>
  <button (click)="goToDetails()"
    class="btn btn-outline-primary rounded-pill"
    i18n="@@student-credits.show-more-details.btn">
    More details
</button>
</div>

