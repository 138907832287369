export const environment = {
  production: true,
  profileName : 'production',
  apiEndpoint: 'https://lessons.callanonline.com/api',
  staticPagesBase: 'https://callanonline.com',
  fileEndpoint: 'https://lessons.callanonline.com/files',
  bookingEndpoint: 'https://booking.callanonline.com/api',
  authEndpoint: 'https://casa.callanonline.com',
  serverBase: 'https://my.callanonline.com',
  casaClientId: 'ij30y8fhVIhq9GHiuvxbiu98hgDjhoibCOhoHWIUohofHFOIQPhishbuifbuoih8209yhfphbohioh90hOIBOIUashfobva839h',
  casaRoot: 'https://casa.callanonline.com',
  cspaApiEndpoint: 'https://api.cspa.callanonline.com',
  cspaUi: 'https://cspa.callanonline.com',
  cspaSocketEndpoint: 'https://api.cspa.callanonline.com/cspa-websocket',
  lessonWsEndpoint: 'wss://lessons.callanonline.com/ws',
  cspaWsEndpoint: 'wss://api.cspa.callanonline.com/cspa-websocket',


  analyticsUA: 'UA-69580833-1',
  adsTrackingCode: 'AW-977652603',
  adsTransactionLabel: 'XSmtCOa28JoBEPuWl9ID',
  pusher: {
    key: 'f44c18355e59a0ac4f10',
    cluster: 'eu'
  },

  callanonlineId : 1,
  supportedUiLanguages: ['en', 'pl', 'es', 'it', 'de' , 'ru' /*, 'ja'*/],
  scheduleBridgeEnabled: false
};
